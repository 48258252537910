<template>
    <v-app>
    <Navigation/>
    <v-main>
      <v-container class="py-8 px-6 grey lighten-4">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Navigation from '@/components/shared/Navigation.vue'
  export default {
  components: { Navigation },
    data: () => ({
      Nav: 'Aplication'
    }),
  }
</script>